import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { HttpBaseService } from '@services/http-base.service'
import { Subject } from 'rxjs/internal/Subject'
import { BookingPackage, DeviceInfo, ServiceIds } from '../../../services/proficloud.interfaces'
import { ProficloudService } from '../../../services/proficloud.service'
import { DeviceService } from './device.service'

export interface DeviceBillingSubscription {
  endpointIds: string[]
  expiryDate: string
  id: string
  orgId: string
  pollDate: string
  quantity: number
  smartServiceId: ServiceIds
  showCancelOption?: boolean
  bookedPackage: BookingPackage
  bookedPackageId: string
  startDate: string
  endDate: string
  trialEndDate: string
  referenceCode: string
  internalOrderId: string
  nextSubscriptionPhase: string
  isInTrialPeriod: boolean
}

export interface DeviceBillingSubscriptionResponse {
  data: DeviceBillingSubscription[]
}

@Injectable({
  providedIn: 'root',
})
export class DeviceBillingService {
  subscriptions: DeviceBillingSubscription[] = []

  bookingPackages: BookingPackage[]

  fetchedDeviceBillingSubscriptions$ = new Subject()

  constructor(
    public deviceService: DeviceService,
    public proficloud: ProficloudService,
    public http: HttpClient,
    private httpBase: HttpBaseService
  ) {
    this.fetchBookingPackages()
    this.fetchDeviceBillingSubscriptions()
  }

  organisationSwitchedSubscription = this.proficloud.organisationSwitched$.subscribe((organisation) => {
    this.fetchDeviceBillingSubscriptions()
  })

  fetchDeviceBillingSubscriptions() {
    this.subscriptions = []
    let deviceBillingUrl = this.httpBase.backendUrls.deviceBillingUrl + '/api/v1/Subscriptions'
    this.http.get<DeviceBillingSubscriptionResponse>(deviceBillingUrl).subscribe((response) => {
      this.subscriptions = response.data.filter((sub) => sub.smartServiceId === 'dmsAddonBasic')
      this.fetchedDeviceBillingSubscriptions$.next(null)
    })
  }

  fetchBookingPackages() {
    const url = `${this.httpBase.backendUrls.billingBaseUrl}/dmsAddonBasic/bookingpackages`

    this.proficloud.http.get<BookingPackage[]>(url).subscribe({
      next: (data) => {
        this.bookingPackages = data
      },
      error: (err: HttpErrorResponse) => {
        // Do nothing
      },
    })
  }

  setDevicesToSubscription(subscriptionId: string, endpointIds: string[]) {
    const url = this.httpBase.backendUrls.deviceBillingUrl + '/api/v1/Subscriptions/' + subscriptionId
    return this.proficloud.http.put(url, { endpoint_ids: endpointIds })
  }

  cancelSubscription(subscriptionId: string) {
    const url = this.httpBase.backendUrls.deviceBillingUrl + '/api/v1/Subscriptions/' + subscriptionId

    return this.proficloud.http.delete(url)
  }

  /////// helper //////////////////

  // TODO: This must be moved to a pipe or calculated once on init, not called from the template!
  countSubscribedDevices() {
    let count = 0
    this.subscriptions.forEach((sub) => {
      try {
        count += sub.endpointIds.length
      } catch {}
    })

    return count
  }

  // TODO: This must be moved to a pipe or calculated once on init, not called from the template!
  countSubscriptionSlots() {
    let count = 0
    this.subscriptions.forEach((sub) => {
      count += sub.quantity
    })

    return count
  }

  // TODO: This must be moved to a pipe or calculated once on init, not called from the template!
  isDeviceInSubscriptions(endpointId: string) {
    return this.subscriptions.find((sub) => sub.endpointIds?.includes(endpointId))
  }

  devicesInSubscriptions(): DeviceInfo[] {
    if (this.deviceService.devices) {
      return this.deviceService.devices.filter((device) => this.isDeviceInSubscriptions(device.endpointId))
    } else {
      return []
    }
  }
}
